import { useState } from 'react';
import { ProductStatusEnum } from '../../../common/enums/productStatusEnum';

const DEFAULT_TARGET_ID = ProductStatusEnum.AtClient;

let currentTargetId = DEFAULT_TARGET_ID;

export const getCurrentTargetId = () => currentTargetId;

export const setCurrentTargetId = (newTargetId: ProductStatusEnum) => {
  currentTargetId = newTargetId;
}