import { NavMenuItem, SearchBar, TsuLoading } from '@/common/components';
import { SERVER_STATIC_FILES_URL } from '@/globals';
import {
  BannerUpdateInput,
  ColorUpdatesInput,
  FooterUpdatesInput,
  GetThemeDocument,
  NavigationBarUpdatesInput,
  PageHeaderUpdatesInput,
  UpdateThemeRequestInput,
  useGetThemeQuery,
  useUpdateThemeMutation,
} from '@/graphql';
import { useApolloClient } from '@apollo/client';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { omit } from 'lodash';
import { useSnackbar } from 'notistack';
import omitDeep from 'omit-deep-lodash';
import { PropsWithChildren, useState } from 'react';
import ColorField from './ColorField';
import { CustomThemeBuilderLoader } from './CustomThemeBuilderLoader';
import { FontsField } from './FontsField';
import { InfoTooltip } from './InfoTooltip';
import { LogoField } from './LogoField';
// import { SortableContainer, SortableElement } from 'react-sortable-hoc';

type CustomUpdateThemeRequestInput = Omit<UpdateThemeRequestInput, 'banners'> & {
  banners: UpdateThemeRequestInput['banners'] | BannerUpdateInput[];
};

const themeFields: { fieldName: keyof CustomUpdateThemeRequestInput; title: string }[] = [
  { fieldName: 'logo', title: 'Logo' },
  { fieldName: 'fonts', title: 'Fonts' },
  { fieldName: 'primary', title: 'Primary' },
  // { fieldName: 'secondary', title: 'Secondary' },
  { fieldName: 'navigationBar', title: 'Navigation Header' },
  { fieldName: 'background', title: 'Background' },
  { fieldName: 'footer', title: 'Footer' },
  { fieldName: 'pageHeader', title: 'Page Header' },
  { fieldName: 'banners', title: 'Banners' },
];

export function ThemeForm() {
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  // Form state
  const [themeInput, setThemeInput] = useState<CustomUpdateThemeRequestInput>();

  // Queries & mutations
  const {
    data: theme,
    loading: isThemeLoading,
    error: getThemeError,
  } = useGetThemeQuery({
    onCompleted(data) {
      if (!data.customerTheme) return;
      setThemeInput({
        ...omit(data.customerTheme, 'customerId'),
        banners: data.customerTheme.banners,
        // banners: Array(data.customerTheme.banners.length).fill(null),
        logo: null,
      });
    },
  });
  const [mutate, { loading: isUpdatingTheme, error: updateThemeError }] = useUpdateThemeMutation();

  // Derived state
  const loading = isThemeLoading || isUpdatingTheme;
  const error = getThemeError || updateThemeError;
  const logoSource = themeInput?.logo
    ? `${SERVER_STATIC_FILES_URL}/${themeInput.logo}`
    : theme?.customerTheme?.logo
    ? `${SERVER_STATIC_FILES_URL}/${theme.customerTheme.logo}`
    : undefined;

  async function saveTheme() {
    if (!themeInput) return;
    let cleanedInput = omitDeep(themeInput, '__typename') as UpdateThemeRequestInput;
    // If there are strings in banners then convert them to null (it means user has not uploaded a file)
    cleanedInput = {
      ...cleanedInput,
      banners: cleanedInput.banners?.map((b) => (typeof b === 'string' ? null : b)),
    };
    await mutate({ variables: { input: cleanedInput } });
    enqueueSnackbar('Updated Theme', { variant: 'success' });
    client.refetchQueries({ include: [GetThemeDocument] });
  }

  // checking if the banner is a new upload or an existing one
  function isBannerUploadInput(bannerObj: any): bannerObj is BannerUpdateInput {
    return (
      bannerObj &&
      (bannerObj.file === undefined || bannerObj.file instanceof File || typeof bannerObj.file === 'object') &&
      (bannerObj.href === undefined || typeof bannerObj.href === 'string')
    );
  }

  return (
    <TsuLoading
      loading={loading}
      error={error}
      loader={
        <Stack>
          {Array(10)
            .fill(0)
            .map((_, i) => (
              <CustomThemeBuilderLoader key={i} />
            ))}
        </Stack>
      }
      justifyContent="start"
    >
      {/* {themeFields
        .filter((f) => !!f)
        .map(({ fieldName, title }, i) => (
          <Accordion key={'accordion-' + i} sx={{ width: '100%' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ alignItems: 'center' }}>
              <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
                <Stack direction="row">
                  <Typography mr={1}>{title}</Typography>
                </Stack>
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ display: 'flex' }}>
              {themeInput ? (
                <SmartThemeField
                  key={i}
                  name={fieldName}
                  field={themeInput[fieldName]!}
                  onChange={(name, value) => setThemeInput({ ...themeInput, [name]: value })}
                />
              ) : null}
            </AccordionDetails>
          </Accordion>
        ))} */}
      <ThemeAccordion title="Theme">
        <Stack spacing={2}>
          <Box my={1} width="100%">
            <LogoField
              width={200}
              height={200}
              logoSrc={logoSource}
              onChange={(v) => themeInput && setThemeInput({ ...themeInput, logo: v })}
            />
          </Box>
          <Stack my={1} direction="row" width="100%" spacing={2}>
            {themeInput && (
              <>
                <FontsField
                  label="Body"
                  value={themeInput?.fonts?.body ?? undefined}
                  onChange={(v) =>
                    themeInput &&
                    setThemeInput({ ...themeInput, fonts: { ...themeInput.fonts, body: v } })
                  }
                  sx={{ flexGrow: 1 }}
                />
                <FontsField
                  label="Navigation and Headings"
                  value={themeInput?.fonts?.navigationAndHeading ?? undefined}
                  onChange={(v) =>
                    themeInput &&
                    setThemeInput({
                      ...themeInput,
                      fonts: { ...themeInput.fonts, navigationAndHeading: v },
                    })
                  }
                  sx={{ flexGrow: 1 }}
                />
              </>
            )}
          </Stack>
          <ColorFieldRenderer
            field={themeInput?.primary ?? undefined}
            onChange={(v) =>
              themeInput && setThemeInput({ ...themeInput, primary: v as ColorUpdatesInput })
            }
          />
        </Stack>
      </ThemeAccordion>
      <ThemeAccordion title="Navigation Header">
        <Container>
          <ColorFieldRenderer
            field={themeInput?.navigationBar ?? undefined}
            onChange={(v) =>
              themeInput &&
              setThemeInput({ ...themeInput, navigationBar: v as NavigationBarUpdatesInput })
            }
          />
          <AppBar elevation={0} position="static">
            <Toolbar sx={{ backgroundColor: themeInput?.navigationBar?.background }}>
              <Stack direction="row">
                <NavMenuItem to="#">Menu 1</NavMenuItem>
                <NavMenuItem to="#">Menu 2</NavMenuItem>
                <NavMenuItem to="#">Menu 3</NavMenuItem>
              </Stack>
            </Toolbar>
          </AppBar>
        </Container>
      </ThemeAccordion>
      <ThemeAccordion title="Logo Bar">
        <ColorFieldRenderer
          field={themeInput?.pageHeader ?? undefined}
          onChange={(v) =>
            themeInput &&
            setThemeInput({ ...themeInput, pageHeader: v as PageHeaderUpdatesInput })
          }
        />
        <SearchBar />
      </ThemeAccordion>
      <ThemeAccordion title="Main Body">
        <ColorFieldRenderer
          field={themeInput?.background ?? undefined}
          onChange={(v) =>
            themeInput && setThemeInput({ ...themeInput, background: v as ColorUpdatesInput })
          }
        />
      </ThemeAccordion>
      <ThemeAccordion title="Footer">
        <ColorFieldRenderer
          field={themeInput?.footer ?? undefined}
          onChange={(v) =>
            themeInput && setThemeInput({ ...themeInput, footer: v as FooterUpdatesInput })
          }
        />
      </ThemeAccordion>
      <ThemeAccordion title="Banners">
        <Stack>
          {(themeInput?.banners ?? []).map((bannerUpdateInput, i) => {
            // Fallback to the existing theme's banner file name (string)
            const file = bannerUpdateInput?.file?.name;
            let source: string | File | undefined = file
              ? `${SERVER_STATIC_FILES_URL}/${file}`
              : undefined;
            // If we have updated a banner with an uploaded file, then
            // pass that File instead
            if (themeInput!.banners![i] && isBannerUploadInput(themeInput!.banners![i])) {
              const newUploadedBanner = themeInput!.banners![i] as BannerUpdateInput;
              source = newUploadedBanner.file?.name;
            }
            return (
              <Box key={i} my={1} width="100%" border={1} borderRadius={4}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <LogoField
                    id={`banner_upload_${i}`}
                    width={200}
                    height={50}
                    logoSrc={source}
                    alt={i.toString()}
                    onChange={(newFile) => {
                      const updatedBanners = themeInput!.banners!.map((oldFile, j) =>
                        j === i ? newFile : oldFile
                      );
                      // @ts-ignore
                      setThemeInput({ ...themeInput!, banners: updatedBanners });
                    }}
                    noBorder
                  />
                  <Button
                    variant="contained"
                    onClick={() => {
                      // @ts-ignore
                      const tempBanners = themeInput!.banners!.filter((banner, j) => j !== i);
                      const updatedBanners = tempBanners.concat();
                      // @ts-ignore
                      setThemeInput({ ...themeInput!, banners: updatedBanners });
                    }}
                  >
                    Delete
                  </Button>
                  <TextField
                    label="Hyperlink"
                    value={themeInput!.banners![i]?.href}
                    onChange={(event) => {
                      const newHref = event.target.value;
                      const updatedBanners = themeInput!.banners!.map((banner, j) =>
                        j === i ? { ...banner, href: newHref} : banner
                      );

                       // @ts-ignore
                       setThemeInput({ ...themeInput!, banners: updatedBanners });
                      console.log("Update Href: ", updatedBanners);
                    }}
                  />
                  <MenuIcon className="banner-handle" fontSize="large" />
                </Stack>
              </Box>
            );
          })}
          <Box my={1} width="100%" border={1} borderRadius={4}>
            <Stack direction="row" spacing={1} alignItems="center">
              <LogoField
                id="add_banner_upload"
                width={200}
                height={50}
                onChange={(newFile) => {
                  const updatedBanners = [...themeInput!.banners!, {file: newFile, href: ""}];
                  console.log(updatedBanners);
                  // @ts-ignore
                  setThemeInput({ ...themeInput!, banners: updatedBanners });
                  console.log("theme input: ", themeInput);
                }}
                noBorder
              />
              <Typography>Add Banner</Typography>
            </Stack>
          </Box>
        </Stack>
      </ThemeAccordion>
      <Stack sx={{ width: '100%' }} direction="row" justifyContent="end">
        <Button variant="contained" onClick={saveTheme} sx={{marginTop: 3}}>
          Save
        </Button>
      </Stack>
    </TsuLoading>
  );
}

type ThemeAccordionProps = PropsWithChildren<{
  title?: string;
}>;

function ThemeAccordion(props: ThemeAccordionProps) {
  return (
    <Accordion sx={{ width: '100%' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ alignItems: 'center' }}>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <Stack direction="row">
            <Typography mr={1}>{props.title}</Typography>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ display: 'flex' }}>{props.children}</AccordionDetails>
    </Accordion>
  );
}

function TooltipWithLabel({ tip, title }: { tip?: string; title: string }) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography>{title}</Typography>
      <InfoTooltip tip={tip ?? title} />
    </Stack>
  );
}

type ColorFieldRendererProps = {
  field?:
    | ColorUpdatesInput
    | NavigationBarUpdatesInput
    | FooterUpdatesInput
    | PageHeaderUpdatesInput;
  onChange?: (v: ColorFieldRendererProps['field']) => void;
};

function ColorFieldRenderer(props: ColorFieldRendererProps) {
  if (!props.field) return null;
  return (
    <Box pb={2} mr={2}>
      {Object.keys(props.field)
        .filter((k) => k !== '__typename')
        .map((key, i) => {
          return (
            <Box key={`primary_${i}`} pb={2} mr={2}>
              <ColorField
                label={<TooltipWithLabel title={key.charAt(0).toUpperCase() + key.slice(1)} />}
                color={(props.field as any)[key as any] ?? ''}
                onChange={(v) => props.onChange?.({ ...props.field, [key as any]: v })}
              />
            </Box>
          );
        })}
    </Box>
  );
}
